import { HandbookDataRow } from '@emrm/handbooks/types';
import { City, S3File } from '@emrm/common/types';

import { BudgetManualBlock } from './manual-block.interface';
import { BudgetTableAutoBlock } from './auto-block.interface';
import { BudgetTableBlockedCells } from './blocked-cells.type';

export type BudgetTable = {
	_id: string;
	createdAt: string;
	city: City;
	month: HandbookDataRow;
	year: HandbookDataRow;
	type_code: string;
	metadata: BudgetTableMetadata[];

	blocking: BudgetManualBlock;
	auto_block: BudgetTableAutoBlock;
	blocked_cells: BudgetTableBlockedCells;

	data: BudgetTableRow[];
	general_data: BudgetTableSummary;
};

export const budgetTableInfoKeys = [
	'_id',
	'createdAt',
	'city',
	'month',
	'year',
	'type_code',
	'metadata',
] as const;

export type BudgetTableInfo = Pick<
	BudgetTable,
	(typeof budgetTableInfoKeys)[number]
>;

export const budgetTableBlockingKeys = [
	'blocking',
	'auto_block',
	'blocked_cells',
] as const;

export type BudgetTableBlocking = Pick<
	BudgetTable,
	(typeof budgetTableBlockingKeys)[number]
>;

export const budgetTableDataKeys = ['data', 'general_data'] as const;

export type BudgetTableData = Pick<
	BudgetTable,
	(typeof budgetTableDataKeys)[number]
>;

export type BudgetTableMetadata = {
	_id: string;
	sort: number;
	name: string;
	rus_name: string;
	table_id: string;
	handbook_code: string;
	handbook_column_name: string;
	handbook_filter_name: string;
	type_column: {
		_id: string;
		name: BudgetTableCellType;
	};
};

export type BudgetTableRow = {
	_id: string;
	sort: number;
	table_id: string;
	parent_row_id: string;
	depth_level: number;
	cells: BudgetTableCell[];
	has_comments: boolean;
	report_08: boolean;
};

export type BudgetTableCell = {
	_id: string;
	sort: number;
	row_id: string;
	metadata_id: string;
	access: boolean;
	value: BudgetTableCellValue;
	property: BudgetTableCellProperty;
};

export type BudgetTableCellProperty = {
	_id?: string;
	background_token: string;
	color_token: string;
};

export const BudgetTableCellType = {
	Handbook: 'handbook',
	String: 'string',
	Number: 'int',
	File: 'file',
	User: 'user',
} as const;

export type BudgetTableCellType =
	(typeof BudgetTableCellType)[keyof typeof BudgetTableCellType];

export type BudgetTableCellValue =
	| HandbookCellValue
	| UserCellValue
	| S3File[]
	| string
	| number;

export type HandbookCellValue = { id: string; value: string };
export type UserCellValue = { id: number; value: string };

export type BudgetTableSummary = {
	plan: number;
	plan_refund: number;
	plan_return: number;
	fact: number;
	fact_refund: number;
	fact_return: number;
};
